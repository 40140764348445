<template>
  <v-container>
    <v-row v-if="ready">
      <spinner style="margin: 400px 500px;"></spinner>
    </v-row>
    <v-row v-else>
      <div class="ml-3">
        <v-card-text
          class="mb-n3 mt-2 ml-1 blue-grey--text"
          style="font-size: 18px; font-weight: bold"
          >Dashboard</v-card-text
        >

        <!--      <PageTitle text="Overview" />-->
      </div>

      <div class="layout mt-2">
        <v-card
          class="d-flex flex-wrap flex-grow-1 mb-6"
          style="justify-content: space-between;"
          color="transparent"
          flat
        >
          <CardName
            title="Total Deliveries"
            color-type="success"
            :card-image="siteImage"
            :count="delivery"
            description="Total Inventory"
          />
          <CardName
            title="Total Orders"
            color-type="#ffcb94"
            :card-image="orderImage"
            :count="orders"
            description="Total confirmed & delivered orders"
          />
          <CardName
            title="Total Pending"
            color-type="#b196e6"
            :card-image="salesImage"
            :count="pendingOrders"
            description="In the last 24hrs"
          />
          <CardName
            title="Cars"
            color-type="#fdc9b9"
            :card-image="userImage"
            :count="cars"
            description="Total cars"
          />
        </v-card>
      </div>
    </v-row>
    <v-row>
      <v-col cols="12" class="white box-shadow-light">
        <div class="">
          <h3
            style="margin-left: 20px; margin-bottom: 30px; margin-top: 15px"
            class="blue-grey--text"
          >
            Performance
          </h3>

          <v-col class="d-flex mt-n1" cols="12" sm="6">
            <v-select
              dense
              style="font-size: 15px"
              class="mx-4"
              :items="branches"
              label="Select Branch"
              outlined
              v-model="selectedBranchChart"
              item-value="id"
              item-text="name"
            ></v-select>
          </v-col>
        </div>

        <div class="mt-2 ml-2">
          <v-skeleton-loader
            class="mx-auto"
            max-width="1000"
            type="card"
            v-if="state"
          ></v-skeleton-loader>
          <apexchart
            v-else
            type="bar"
            height="400"
            width="100%"
            :options="graphData"
            :series="series"
          ></apexchart>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import PageTitle from "../components/PageTitle";
import CardName from "../components/CardName";
import * as axios from "axios";
import Spinner from "vue-simple-spinner";
import VueApexCharts from "vue-apexcharts";
//import VueBarGraph from "vue-bar-graph";

export default {
  name: "Dashboard",
  components: { CardName, spinner: Spinner, apexchart: VueApexCharts },
  data: function() {
    return {
      ready: false,
      cars: 0,
      delivery: 0,
      orders: 0,
      branches: [],
      series: [],
      graphData: {},
      selectedBranchChart: "",
      totalOrdersInBranch: [],
      recordDates: [],
      state: false,
      pendingOrders: 0,
      siteImage: require("@/assets/deliver.svg"),
      orderImage: require("@/assets/confirm.svg"),
      salesImage: require("@/assets/pending.svg"),
      userImage: require("@/assets/truck.svg")
    };
  },

  watch: {
    selectedBranchChart(value) {
      this.totalOrdersInBranch = [];
      this.recordDates = [];
      this.getChart(value);
      this.state = true;
    }
  },

  methods: {
    getStats() {
      this.ready = true;
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/dashboard/transport/statistics`
      })
        .then(response => {
          if (response.status === 200) {
            this.ready = false;
            let results = response.data;
            this.cars = results.totalCars;
            this.delivery = results.totalDeliveries;
            this.pendingOrders = results.totalPendingOrders;
            this.orders = results.totalOrders;
          }
          //console.log(response);
        })
        .catch(err => {
          this.ready = false;
          if (err.response.status === 401) {
            this.$router.push({ name: "loginMaster.login" });
          } else {
            // console.log(err);
          }
        });
    },

    getBranch() {
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/branches`
      })
        .then(response => {
          if (response.status === 200) {
            this.branches = response.data;
          }
        })
        .catch(err => {
          this.ready = false;
          if (err.response.status === 401) {
            this.$router.push({ name: "loginMaster.login" });
          } else {
            // console.log(err);
          }
        });
    },

    getChart(value) {
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/dashboard/transport/orders/readings?branchId=${value}&startDate=&endDate=`
      })
        .then(response => {
          if (response.status === 200) {
            this.state = false;
            let results = response.data;
            //console.log(results);
            results.forEach(i => {
              this.totalOrdersInBranch.push(i.totalOrders);
             // console.log(this.totalOrdersInBranch);
              this.recordDates.push(i.key);
            });

            this.series = [
              {
                name: "Total Orders",
                data: this.totalOrdersInBranch
              }
            ];
            this.graphData = {
              chart: {
                height: 300,
                type: "bar"
              },
              plotOptions: {
                bar: {
                  columnWidth: "50%",
                  endingShape: "flat"
                }
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                width: 2
              },

              grid: {
                row: {
                  colors: ["#fff", "#f2f2f2"]
                }
              },
              xaxis: {
                labels: {
                  rotate: -45
                },
                categories: this.recordDates,
                tickPlacement: "on"
              },
              yaxis: {
                title: {
                  text: "Stations"
                }
              },
              fill: {
                type: "gradient",
                gradient: {
                  shade: "light",
                  type: "horizontal",
                  shadeIntensity: 0.25,
                  gradientToColors: undefined,
                  inverseColors: true,
                  opacityFrom: 0.85,
                  opacityTo: 0.85,
                  stops: [50, 0, 100]
                }
              }
            };
            //this.totalOrdersInBranch = results;
          }
        })
        .catch(err => {
          this.ready = false;
          if (err.response.status === 401) {
            this.$router.push({ name: "loginMaster.login" });
          } else {
            // console.log(err);
          }
        });
    }
  },

  created() {
    this.getStats();
    this.getBranch();
    //this.getChart();
  }
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
}

.stretch-card {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.grid-margin {
  margin-bottom: 20px;
}

.card {
  border: 2px;
  border-radius: 2px;
  display: flex;
  height: 480px;
  flex-direction: column;
  background-color: #fff;
  background-clip: border-box;
}
.card2 {
  border: 2px;
  border-radius: 2px;
  display: flex;
  height: 180px;
  width: 350px;
  margin-top: -2px;
  flex-direction: column;
  background-color: #fff;
}
.block {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.extreme {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
}
.stretch-card > .card {
  width: 100%;
  min-width: 100%;
}
.bottom {
  border-bottom: 1px solid #aacfcf;
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  color: #2f4053;
}
.button {
  background-color: #04a9f3;
  border: none;
  font-size: 10px;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.button1 {
  background-color: #1eb2a6;
  border: none;
  font-size: 10px;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.button2 {
  background-color: #ffa3ad;
  border: none;
  font-size: 10px;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.layout {
  display: flex;
  flex-direction: row;
  margin-top: -30px;
  flexwrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  alignt-content: stretch;
}

.container2 {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  alignt-content: stretch;
}

.button3 {
  background-color: #b590ca;
  border: none;
  font-size: 10px;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
#app {
  margin: 0 auto;
  padding: 20px;
  max-width: 600px;
}
</style>
