<template>
  <div
    class="card box-shadow-light"
    style="width:270px; height: 150px; margin-top: 20px; background-color: #ffffff"
  >
    <div class="cardbody">
      <div class="arrange">
        <div style="width: 200px">
          <v-img width="60" class=" ml-3" height="60" :src="cardImage"></v-img>
        </div>
        <!--        <v-icon :color="colorType" size="50">{{ iconType }}</v-icon>-->
        <div class="ml-3">
          <p
            class=" font-weight-regular"
            style="font-size: 14px; margin-bottom: -2px"
          >
            {{ title }}
          </p>
          <h2 class="font-weight-bold">{{ count }}</h2>
        </div>
      </div>
      <v-divider class="mt-5" color="primary"></v-divider>
      <h6 class="grey--text mt-2">{{ description }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardName",
  props: ["title", "count", "cardImage", "colorType", "description"]
};
</script>

<style scoped>
.card .cardbody {
  padding: 1.88rem 1.81rem;
  padding-top: 1.88rem;
  padding-right: 1.81rem;
  padding-bottom: 1.88rem;
  padding-left: 1.81rem;
}
.arrange {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardbody {
  flex: 1 1 auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}
</style>
